<template>
  <div>
    <baidu-map-view
      v-if="showMap"
      ref="map"
      :map-data="{
        data: [{ lng: lng, lat: lat }],
        mapType: 1,
        showButton: true,
        width: '100%',
        preRender: true,
      }"
      @confirmEvent="confirmEvent"
    />
    <div v-if="showForm" style="margin:14px">
      <!--    <van-overlay :show="showMap" style="width: 100%" z-index="999">-->

      <!--    </van-overlay>-->
      <van-nav-bar title="店铺新增" />
      <van-form @submit="save">
        <van-field
          v-model="formData.deptName"
          name="deptName"
          label="店铺名称"
          placeholder="请输入店铺名称"
          :rules="[{ required: true, message: '请填写店铺名称' }]"
        />
        <van-field
          v-model="formData.shopName"
          name="shopName"
          label="店招"
          placeholder="请输入店招"
          :rules="[{ required: true, message: '请填写店招' }]"
        />
        <van-field
          v-model="formData.creditCode"
          name="creditCode"
          label="社会统一信用代码"
          placeholder="请输入社会统一信用代码"
        />
        <van-field
          v-model="formData.companyAddress"
          name="companyAddress"
          label="经营地址"
          placeholder="请输入经营地址"
          :rules="[{ required: true, message: '请填写经营地址' }]"
        />
        <van-field
          v-model="formData.telephone"
          name="telephone"
          label="店铺电话"
          placeholder="请输入店铺电话"
          :rules="[{ validator, message: '请输入正确的号码' }]"
        />
        <van-field
          v-model="formData.legal_representative"
          name="legal_representative"
          label="法定代表人"
          placeholder="请输入法定代表人"
          :rules="[{ required: true, message: '请填写法定代表人' }]"
        />
        <van-field
          v-model="formData.phone"
          name="phone"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="[{ pattern, message: '请输入正确的号码' }]"
        />
        <!--      选择店铺类型-->
        <shop-type
          :shop-type="formData.shopType"
          @shopTypeChange="shopTypeChange"
        />
        <van-field
          v-model="formData.serviceScope"
          rows="3"
          autosize
          label="经营范围"
          type="textarea"
          maxlength="50"
          placeholder="请输入经营范围"
          :rules="[{ required: true, message: '请输入经营范围' }]"
        />
        <van-field
          v-model="formData.remark"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          maxlength="50"
          placeholder="请输入备注"
          :rules="[{ required: true, message: '请输入备注' }]"
        />
        <van-field
          v-model="formData.regNum"
          name="regNum"
          label="注册号"
          placeholder="请输入注册号"
          :rules="[{ required: true, message: '请填写注册号' }]"
        />
        <!--      选择中队-->
        <team ref="team" :team-id="formData.teamId" @teamChange="teamChange" />
        <!--      选择所属网格-->
        <team-cell
          ref="teamCell"
          :team-id="formData.teamId"
          :cell-id="formData.cellId"
          @teamCellChange="teamCellChange"
        />
        <!--      选择网格管理员-->
        <cell-person
          ref="cellPerson"
          :cell-id="formData.cellId"
          :person-id="formData.personId"
          @cellPersonChange="cellPersonChange"
        />
        <van-field
          v-model="formData.coordinate"
          readonly
          clickable
          label="选择坐标"
          placeholder="请选择坐标"
          is-link
          name="coordinate"
          :rules="[{ required: true, message: '请选择坐标' }]"
          @click="open"
        />

        <van-field
          name="focusObject"
          label="重点监管对象"
          :rules="[{ required: true, message: '请选择是否重点监管对象' }]"
        >
          <template #input>
            <van-radio-group
              v-model="focusObject"
              direction="horizontal"
              @change="focusObjectChange"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          name="outDoor"
          label="门前三包开展情况"
          :rules="[{ required: true, message: '请选择门前三包开展情况' }]"
        >
          <template #input>
            <van-radio-group
              v-model="outDoor"
              direction="horizontal"
              @change="outDoorChange"
            >
              <van-radio name="3">优秀</van-radio>
              <van-radio name="2">良好</van-radio>
              <van-radio name="1">一般</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          name="rubbishPart"
          label="垃圾分类情况"
          autosize
          rows="3"
          :rules="[{ required: true, message: '请选择垃圾分类情况' }]"
        >
          <template #input>
            <van-checkbox-group v-model="rubbishPartArr" direction="horizontal">
              <van-checkbox name="垃圾实行袋装化" shape="square">
                垃圾实行袋装化
              </van-checkbox>
              <van-checkbox name="分类垃圾桶" shape="square">
                分类垃圾桶
              </van-checkbox>
              <van-checkbox name="垃圾分类投放" shape="square">
                垃圾分类投放
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field name="cityVolunteer" label="城管义工参与情况">
          <template #input>
            <van-checkbox-group
              v-model="cityVolunteerArr"
              direction="horizontal"
              :rules="[{ required: true, message: '请选择城管义工参与情况' }]"
            >
              <van-checkbox name="参与过城管义工活动" shape="square">
                参与过城管义工活动
              </van-checkbox>
              <van-checkbox name="是城管义工" shape="square">
                是城管义工
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          name="waterAdd"
          label="是否加水点"
          :rules="[{ required: true, message: '请选择是否加水点' }]"
        >
          <template #input>
            <van-radio-group
              v-model="waterAdd"
              direction="horizontal"
              @change="waterAddChange"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-nav-bar title="照片" />
        <van-field readonly label="近景照片" />
        <div style="padding: 10px;background: white">
          <upload-file :limit="2" @fetch-data="fetchCloseShots" />
          <label style="font-size: 15px;color: red;">
            拍摄照片请尽可能露出门牌
          </label>
        </div>
        <van-field readonly label="远景照片" />
        <div style="padding: 10px;background: white">
          <upload-file :limit="2" @fetch-data="fetchDistantShots" />
          <label style="font-size: 15px;color: red;">
            拍摄照片请尽可能露出门牌
          </label>
        </div>
        <!--        <van-field readonly label="门前三包" />-->
        <!--        <div style="padding: 10px;background: white">-->
        <!--          <upload-file :limit="3" @fetch-data="fetchOutDoorShots" />-->
        <!--        </div>-->
        <van-field readonly label="证件照片" />
        <div style="padding: 10px;background: white">
          <upload-file :limit="3" @fetch-data="fetchCertificateShots" />
        </div>
        <van-nav-bar title="业主信息" />
        <van-field
          v-model="formData.ownerName"
          name="ownerName"
          label="业主姓名"
          placeholder="业主姓名"
          :rules="[{ required: true, message: '请填写业主姓名' }]"
        />
        <van-field
          v-model="formData.ownerPhone"
          name="ownerPhone"
          label="联系方式"
          placeholder="联系方式"
          :rules="[{ pattern, message: '请输入正确的号码' }]"
        />
        <van-field
          v-model="formData.ownerAddress"
          name="ownerAddress"
          label="联系地址"
          placeholder="联系地址"
          :rules="[{ required: true, message: '请填写联系地址' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { Dialog } from 'vant'
  import store from '@/store'
  import uploadFile from '@/components/UploadFile'
  import baiduMapView from '@/components/BaiduMapView'
  import shopType from '@/components/ShopType'
  import team from '@/components/Team'
  import teamCell from '@/components/TeamCell'
  import cellPerson from '@/components/CellPerson'
  import { addShop } from '@/api/workBench/shop/shop'
  import Base64 from '@/util/Base64'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopAdd',
    components: {
      shopType,
      team,
      teamCell,
      cellPerson,
      uploadFile,
      baiduMapView,
    },
    data() {
      return {
        pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,

        lng: 0,
        lat: 0,
        showMap: false,
        showForm: true,
        userId: null,
        focusObject: null, //是否重点
        outDoor: null, //门前三包开展情况
        waterAdd: null, //是否加水点
        rubbishPartArr: [], //垃圾分类开展进度
        cityVolunteerArr: [], //城管义工参与情况
        closeShotsArr: [], //近景照片
        distantShotsArr: [], //远景照片
        outDoorShotsArr: [], //门前三包照片
        certificateShotsArr: [], //证件照片

        formData: {
          deptName: '', //店铺名称
          shopName: '', //店招
          creditCode: '', //社会统一信用代码
          companyAddress: '',
          telephone: '', //店铺电话
          legal_representative: '', //法人
          phone: '', //代表人手机号
          shopType: null,
          serviceScope: '', //经营范围
          remark: '',
          regNum: '', //注册号
          teamId: null,
          cellId: null,
          personId: null,
          coordinate: '', //坐标
          focusObject: '', //是否重点关注对象
          outDoor: '', //门前三包开展情况
          rubbishPart: '', //垃圾分类开展进度
          cityVolunteer: '', //城管义工参与情况
          waterAdd: '', //是否加水点
          ownerName: '',
          ownerPhone: '', //业主联系方式
          ownerAddress: '',
          closeShots: '', //近景照片
          distantShots: '', //远景照片
          outDoorShots: '', //门前三包照片
          certificateShots: '', //证件照片
        },
      }
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      const teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.userId = parseInt(userInfo.User_ID)
      this.formData.personId = this.userId
      this.formData.teamId = parseInt(teamInfo.Team_ID)
      this.formData.cellId = parseInt(teamInfo.Cell_ID)
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopAdd',
        '城管-店铺新增',
        'zzdcg.yy.gov.cn/shopAdd'
      )
    },
    methods: {
      //函数验证
      validator(val) {
        if (val == '') return false
        if (val != '')
          return /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(val)
        return true
      },

      save() {
        if (this.formData.creditCode == '')
          Dialog.alert({
            message: '社会统一信用代码未填写，将由系统生成',
          })
            .then(() => {
              this.onSubmit()
            })
            .catch(() => {
              // on cancel
            })
        else this.onSubmit()
      },
      //提交
      async onSubmit() {
        this.formData.closeShots = this.closeShotsArr.join('|')
        this.formData.distantShots = this.distantShotsArr.join('|')
        this.formData.outDoorShots = this.outDoorShotsArr.join('|')
        this.formData.certificateShots = this.certificateShotsArr.join('|')
        this.formData.rubbishPart = this.rubbishPartArr.join(',')
        this.formData.cityVolunteer = this.cityVolunteerArr.join(',')
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { msg, success } = await addShop({
          Dept_Name: this.formData.deptName,
          Shop_Name: this.formData.shopName,
          Credit_Code: this.formData.creditCode,
          Company_Address: this.formData.companyAddress,
          Telephone: this.formData.telephone,
          Legal_representative: this.formData.legal_representative,
          Phone: this.formData.phone,
          Shop_Type: this.formData.shopType,
          Service_scope: this.formData.serviceScope,
          Remark: this.formData.remark,
          Reg_Num: this.formData.regNum,
          Team_ID: this.formData.teamId,
          Cell_ID: this.formData.cellId,
          Person_ID: this.formData.personId,
          Coordinate: this.formData.coordinate,
          FocusObject: this.formData.focusObject,
          OutDoor: this.formData.outDoor,
          RubbishPart: this.formData.rubbishPart,
          CityVolunteer: this.formData.cityVolunteer,
          WaterAdd: this.formData.waterAdd,
          Owner_Name: this.formData.ownerName,
          Owner_Phone: this.formData.ownerPhone,
          Owner_Address: this.formData.ownerAddress,
          CloseShots: this.formData.closeShots,
          DistantShots: this.formData.distantShots,
          OutDoorShots: this.formData.outDoorShots,
          CertificateShots: this.formData.certificateShots,
          User_ID: this.userId,
        })
        Toast.clear()
        Dialog.alert({
          message: '' + msg,
        }).then(() => {
          // on close
        })
        if (success) {
          this.toList()
        }
      },

      //跳转至列表
      toList(item) {
        this.$router.push({
          path: '/shop',
        })
      },

      //打开地图
      open() {
        this.showMap = true
        this.showForm = false
        this.getLocation()
      },

      //获取当前位置坐标
      getLocation() {
        window.dd
          .getGeolocation({
            targetAccuracy: 100,
            coordinate: 0,
            withReGeocode: false,
            useCache: true, //默认是true，如果需要频繁获取地理位置，请设置false
          })
          .then((res) => {
            this.lng = res.longitude
            this.lat = res.latitude
            let point = this.$refs.map.Convert_GCJ02_To_BD09({
              lng: this.lng,
              lat: this.lat,
            })
            this.lng = point.lng
            this.lat = point.lat

            this.$refs['map'].render()
          })
          .catch((err) => {})
      },

      //地图回调
      confirmEvent(data) {
        this.lng = data.lng
        this.lat = data.lat
        this.formData.coordinate =
          data.lng.toString() + ',' + data.lat.toString()
        this.showMap = false
        this.showForm = true
      },

      //单选框value只能为数字，选择后做一下传值的转化
      focusObjectChange() {
        this.formData.focusObject = this.focusObject == 0 ? '否' : '是'
      },
      outDoorChange() {
        if (this.outDoor == 1) this.formData.outDoor = '一般'
        else if (this.outDoor == 2) this.formData.outDoor = '良好'
        else this.formData.outDoor = '优秀'
      },
      waterAddChange() {
        this.formData.waterAdd = this.waterAdd == 0 ? '否' : '是'
      },

      //选择器回调方法
      shopTypeChange(shopType) {
        this.formData.shopType = shopType
      },
      teamChange(teamId) {
        this.formData.teamId = teamId
        this.$refs['teamCell'].reset()
        this.$refs['cellPerson'].reset()
        this.formData.cellId = null
        this.formData.personId = null
      },
      teamCellChange(cellId) {
        this.formData.cellId = cellId
        this.$refs['cellPerson'].reset()
        this.formData.personId = null
      },
      cellPersonChange(personId) {
        this.formData.personId = personId
      },

      //照片上传回调方法
      fetchCloseShots(pathList) {
        this.closeShotsArr = pathList
      },
      fetchDistantShots(pathList) {
        this.distantShotsArr = pathList
      },
      fetchOutDoorShots(pathList) {
        this.outDoorShotsArr = pathList
      },
      fetchCertificateShots(pathList) {
        this.certificateShotsArr = pathList
      },
    },
  }
</script>

<style scoped></style>
